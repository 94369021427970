<template>
  <div class="page default-page">
    <sections-elem :content="$store.state.page.content.sections"></sections-elem>
  </div>
</template>

<script>
export default {
  name: 'default-page'
}
</script>

<style lang="sass">
.default-page

  // for Leitbild page, just to avoid having a separate template
  h2
    &.is-blue
      +font("bold")
      color: $blue
    &.is-orange
      +font("bold")
      color: $orange
    &.is-green
      +font("bold")
      color: $green
    &.is-purple
      +font("bold")
      color: $purple
</style>